import React from "react"
import { Link } from "gatsby"
//import { Row, Col, Container, Button } from "react-bootstrap"
import { Container } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const pageTitle = "News"

const SecondPage = ({ pageContext, location }) => {

  const {
    breadcrumb: { crumbs },
  } = pageContext
  
  return (
    <Layout pageInfo={{ pageName: "news" }} crumbs={crumbs} location={location} crumbLabel={pageTitle}>
      <SEO title={pageTitle} />

      <Container className="mx-4">
        <h1 className="page-heading">News</h1>


        <h2 className="page-subheading">Recent News Releases</h2>

        <ul>
          <li>April 5, 2020 - <Link to="/news/2020/04/moon-society-tlrc-announcement">Press Release: The Moon Society Acquires The Lunar Resources Company</Link></li>
        </ul>

        <h2 className="page-subheading">Historical News Releases</h2>
        <ul>
          <li>January 1995 - <Link to="/news/1995/01/artemis-project-selling-the-moon">Artemis Project: Selling the Moon</Link> (Gregory Bennett, Analog Magazine)</li>
          <li>January 1995 - <Link to="/news/1995/01/selling-their-way-to-space">Selling Their Way to Space</Link> (Dallas Morning News)</li>
          <li>July 1995 - <Link to="/news/1995/07/living-in-space-science-fact">Living in Space: Science Fiction or Science Fact?</Link> (Huntsville News)</li>
        </ul>

      </Container>
    </Layout>
  )
}

export default SecondPage